/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import axiosConfig from '@/libs/axios'
import axios from 'axios'
import Notifications from '@kyvg/vue3-notification'
// import gAuth from '@/libs/auth/google'
import SvgIcon from '@/components/item-wizard/components/elements/SvgIcon'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import InlineSvg from 'vue-inline-svg'
import ClickOutside from 'vue-click-outside'
import {_} from 'lodash'
import './index.css'

window._ = require('lodash');
const app = createApp(App)
app.config.globalProperties.$axios = axiosConfig

app.component('svg-icon-qouting', SvgIcon)
app.use(router)
app.use(store)
app.use(Notifications)
// app.use(gAuth)
// app.use(VueSvgInlinePlugin)
app.component('inline-svg', InlineSvg)
app.directive('click-outside', ClickOutside)



app.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_API_KEY,
        libraries: "places",
        language: 'en',
    },
})

app.mount('#app')
